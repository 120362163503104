import React from "react";
import { Col, Row } from "react-bootstrap";
export default function Footer() {
    return (
        <>
            <footer className="footer">
                <Row>
                    <div className="footer-container">
                        <Col md={4}>
                            <div className="footer-section working-hours">
                                <h3>Working Hours</h3>
                                <p>Monday - Saturday:<br /><span className="span-footer">09:00AM - 06:00PM</span></p>
                                <p>Sunday<br /><span className="span-footer"> Holiday</span></p>
                                <div className="social-icons">
                                    <a href="https://www.facebook.com/HolidayStarTours" target="_blank"><i className="fa-brands fa-facebook-f fa-xl"></i></a>
                                    <a href="https://www.linkedin.com/company/holiday-star-tours-travels" target="_blank"><i className="fa-brands fa-linkedin-in fa-xl"></i></a>
                                    <a href="https://www.instagram.com/holidaystartours" target="_blank"><i className="fa-brands fa-instagram fa-xl"></i></a>
                                    
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="footer-section contact-info">
                                <h3>Contact Info</h3>
                                <p>Email:<br /><span className="span-footer">contact@holidaystartours.com</span></p>
                                <p>Address:<br /><span className="span-footer">No. 8, 1st Floor, K.B. Aluppy Complex,<br /> Dr. Natesan Road, Royapettah,<br /> Chennai 600014</span></p>
                                <p>Phone Number:<br /><span className="span-footer">+91 94443 70254</span></p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="footer-section newsletter">
                                <h3>Our Newsletter</h3>
                                <div className="newsletter-input">
                                    <input type="email" placeholder="Email Address..." />
                                    <button>Send</button>
                                </div>
                                <p className="newsletter">Don't miss out on the latest updates, subscribe to our newsletter now!</p>
                            </div>
                        </Col>
                    </div>
                </Row>
                <div className="footer-bottom">
                    <p> © HOLIDAYSTAR 2024 - All Rights Reserved | Powered by DGBird Media</p>
                </div>


            </footer >

        </>
    )
}