import React from "react";
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from "react-bootstrap";
export default function Resort() {

    const handleViewPackagesClick = () => {
        const destinationsSection = document.getElementById('destination');
        if (destinationsSection) {
            destinationsSection.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.error('Destinations section not found');
        }
    };

    return (
        <>
            <div className="resort_img">
                <div className="inline_section">
                    <Container className="sec_res">
                        <Row style={{ marginBottom: '10%' }}>
                            <Col md={6} style={{ padding: '0' }}>
                                <div className="girl_bimg">
                                    <div className="girl_img">
                                        <img src="/images/boycap.png" />
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} style={{ padding: '0' }}>
                                <div className="view_pack">
                                    <div className="text-container">
                                        <h4 style={{fontWeight:'bold'}}>Discover the world with Holiday Star!</h4>
                                        <p className="para"> We are your trusted partner in creating unforgettable travel experiences. Whether you're seeking adventure, relaxation, or cultural exploration, Holiday Star has the perfect tour package for you. Let us take you on a journey to the most stunning destinations across the globe.</p>
                                        {/* <p>
                                            Nunc scelerisque viverra mauris in aliquam sem fringilla
                                            urna porttitor rhoncus dolor purus non cursus vitae congue.
                                        </p> */}
                                        <div className="package">
                                            <Button onClick={handleViewPackagesClick} className="view-packages-button"> View Packages</Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </>
    )
}