import React,{useEffect} from 'react';
import './App.css';
import './style.css';
import './whatsapp.css';
import Home from './components/Home';
import { Route, Router, Routes } from 'react-router-dom';
import Aboutus from './components/Aboutus';
import Blogs from './components/Blogs';
import Contact from './components/Contact';
import ScrollToTop from './components/ScrollToTop';
import NavbarComponent from './components/Navbar';
import WhatsAppIcon from './components/whatsApp';
import Destinations from './components/Destinations';
import Malaysianblog from './components/Malasiyanblog';
import Andamanblog from './components/Andamanblog';
import Singaporeblog from './components/Singaporeblog';
function App(){
  return (
    <>
 
      <ScrollToTop />
      <WhatsAppIcon/>
      <NavbarComponent />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Aboutus" element={<Aboutus />} />
        <Route path="/Destinations" element={<Destinations />} />
        <Route path="/Blogs" element={<Blogs />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Malasiyanblog" element={<Malaysianblog/>}/>
        <Route path='/Andamanblog' element={<Andamanblog/>}/>
        <Route path='/Singaporeblog' element={<Singaporeblog/>}/>
      </Routes>

   
    
    </>
  );
}

export default App;
