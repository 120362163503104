import React from "react";
import NavbarComponent from "./Navbar";
import { Link } from "react-router-dom";
import Destination from "./Destination";
import Footer from "./Footer";

export default function Destinations(){
    return(
        <>
    
        <div className="about-bg-subhead">
                <div className="about-subhead">
                    <div className="about-navigate">
                        <div className="about-heading">
                            <h1>Destinations</h1>
                        </div>
                        <div className="about-home-navigate">
                            <ul>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <i class="fa-solid fa-angle-right" style={{ color: '#ffffff' }}></i>
                                </li>
                                <li>
                                    <p>Destinations</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Destination/>
            <Footer/>
            
        
        </>
    )
}