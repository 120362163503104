import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css'; 

export default function Singaporeblog(){
    useEffect(() => {
        AOS.init({
            duration: 2000,
            once: true,
        });
    }, []);
    return(
        <>
        <div className="blog_malasiyan_bg">
        <div className="blog-malasiyacontent">
        <div className="blog-content" data-aos="fade-down">
          <h1 className="blog-title">Singapore In Style: Your Guide to The Best Times to Travel and Things To-Do</h1>
          <img src="images/simgam1.jpg" alt="image" className="blog_img" style={{width:'50%',maxHeight:'550px',margin:'auto',display:'block'}}/>
          <section>
            <h2 className="section-title">1. The Perfect Time to Visit Singapore</h2>
            <p>Singapore’s tropical climate makes it warm and humid throughout the year. However, some seasons are particularly favorable for a visit:</p>
            <ul>
              <li><strong>December to February:</strong> The festive season with cooler temperatures (23°C to 30°C) and vibrant celebrations.</li>
              <li><strong>March to May:</strong> Enjoy the lush greenery and blooming flowers, with temperatures ranging from 24°C to 32°C.</li>
            </ul>
          </section>
          
          <section>
            <h2 className="section-title">2. Places to Visit in Singapore</h2>
            <p>Make sure to visit these must-see spots:</p>
            <ul>
              <li><strong>Marina Bay Sands:</strong> An iconic resort with stunning city views and an evening light show.</li>
              <li><strong>Gardens by the Bay:</strong> A futuristic garden with incredible plant displays and a light show.</li>
              <li><strong>Sentosa Island:</strong> Offers theme parks, beaches, and the S.E.A. Aquarium.</li>
              <li><strong>Chinatown and Little India:</strong> Explore the cultural heritage and vibrant streets.</li>
              <li><strong>Orchard Road:</strong> A shopping haven with options for every budget.</li>
              <li><strong>Clarke Quay:</strong> The heart of Singapore’s nightlife.</li>
              <li><strong>Singapore Botanic Gardens:</strong> A UNESCO World Heritage Site perfect for a peaceful retreat.</li>
            </ul>
          </section>

          <section>
            <h2 className="section-title">3. Travel Tips for the Singapore Visit</h2>
            <ul>
              <li><strong>Dress Smart:</strong> Opt for lightweight, breathable fabrics but don’t shy away from stylish outfits for upscale areas.</li>
              <li><strong>Stay Connected:</strong> Get a local SIM or portable Wi-Fi to stay online and navigate the city easily.</li>
              <li><strong>Sample Local Delicacies:</strong> Don’t miss out on local dishes like Hainanese Chicken Rice and Chilli Crab.</li>
              <li><strong>Plan Ahead:</strong> To avoid long queues, book tickets in advance and visit popular spots during off-peak hours.</li>
            </ul>
          </section>

          <p className="closing-remark">
            Singapore is a mesmerizing and radiant city, promising a memorable experience whether you’re contemplating its high-rising futuristic skyline or diving into its culinary delights. Safe travels, and enjoy the adventure!
          </p>
        </div>
            </div>
            </div>
        
        </>
    )
}