import React, { useEffect } from "react";
import AOS from 'aos';
import { Card, Col, Container, Row, Button } from "react-bootstrap";
import Footer from "./Footer";

export default function Destination() {
  const destinations = [
    {
      id: 1,
      image: "/images/malasiya.jpg",
      title: "Malaysia Packages",
      // details: [
      //   "2 NIGHTS / 3 DAYS",
      //   "3 NIGHTS / 4 DAYS",
      //   "4 NIGHTS / 5 DAYS",
      //   "5 NIGHTS / 6 DAYS"
      // ]
    },
    {
      id: 2,
      image: "/images/sing.jpg",
      title: "Singapore Packages",
      // details: [
      //   "2 NIGHTS / 3 DAYS",
      //   "3 NIGHTS / 4 DAYS",
      //   "4 NIGHTS / 5 DAYS",
      //   "5 NIGHTS / 6 DAYS"
      // ]
    },
    {
      id: 3,
      image: "/images/thai1.jpg",
      title: "Thailand Packages",
      // details: [
      //   "2 NIGHTS / 3 DAYS",
      //   "3 NIGHTS / 4 DAYS",
      //   "4 NIGHTS / 5 DAYS",
      //   "5 NIGHTS / 6 DAYS"
      // ]
    },
    {
      id: 4,
      image: "/images/dubai1.avif",
      title: "Dubai Packages",
      // details: [
      //   "2 NIGHTS / 3 DAYS",
      //   "3 NIGHTS / 4 DAYS",
      //   "4 NIGHTS / 5 DAYS",
      //   "5 NIGHTS / 6 DAYS"
      // ]
    },
    {
      id: 5,
      image: "/images/Andaman1.webp",
      title: "Andaman Packages",
      // details: [
      //   "2 NIGHTS / 3 DAYS",
      //   "3 NIGHTS / 4 DAYS",
      //   "4 NIGHTS / 5 DAYS",
      //   "5 NIGHTS / 6 DAYS"
      // ]
    },
    
    {
      id: 6,
      image: "/images/ker.jpg",
      title: "Kerala Packages",
      // details: [
      //   "2 NIGHTS / 3 DAYS",
      //   "3 NIGHTS / 4 DAYS",
      //   "4 NIGHTS / 5 DAYS",
      //   "5 NIGHTS / 6 DAYS"
      // ]
    },
  ];

  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  return (
    <>
      <div id="destination" className="destins">
        <div className="des_heading" data-aos="fade-down" data-aos-duration="2000">
          <h1>Destinations</h1>
          <p>
            Explore the world with Holiday Star! Discover the beauty, culture, and adventure that awaits you!
          </p>
        </div>
        <div className="card_detail">
          <div className="cards" data-aos="fade-up" data-aos-easing="ease-out-back">
            <Container>
              <Row style={{ marginBottom: "5%" }}>
                {destinations.map((destination) => (
                  <Col key={destination.id} md={4} style={{marginBottom:'5%'}}>
                    <Card>
                      <Card.Body>
                        <Card.Img src={destination.image} />
                        <div className="text_card">
                          <Card.Title>{destination.title}</Card.Title>
                          {/* <Card.Text>
                            <ul>
                              {destination.details.map((detail, index) => (
                                <li key={index}>{detail}</li>
                              ))}
                            </ul>
                          </Card.Text> */}
                          {/* <Button className="view-packages-button"><i class="fa-solid fa-phone-volume" style={{color: "#ffffff"}}></i>&nbsp;&nbsp;&nbsp;Book Now</Button> */}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
