import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

export default function NavbarComponent() {
    const location = useLocation();
    return (
        <>
            <div className="navs">
                <div className="bar">
                    <div className="address">
                        <i class="fa-solid fa-location-dot"></i>
                        <p style={{ paddingLeft: '10px' }}>Chennai </p>
                    </div>
                    <Navbar.Brand href="/">
                        <img
                            src="images/hslogo.png"
                            className="taj_image"
                            alt="logo"
                           />

                    </Navbar.Brand>
                    <div className="social_media">
                        <a href="https://www.facebook.com/HolidayStarTours" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-facebook-f "></i></a>
                        <a href="https://www.linkedin.com/company/holiday-star-tours-travels" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-linkedin-in "></i></a>
                        <a href="https://www.instagram.com/holidaystartours" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-instagram"></i></a>

                    </div>
                </div>
            </div>
            <Navbar collapseOnSelect expand='lg' sticky="top" style={{ backgroundColor: '#f6f7f7', position: 'sticky', top: '0', zIndex: '1000', paddingBottom: '2%' }}>
                <Container>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-center">
                        <Nav className="list_navbars">
                            <ul className="list_items d-flex justify-content-center">
                                <Nav.Link as={Link} to="/" className={`nav-item-spacing ${location.pathname === '/' ? 'active' : ''}`}>Home</Nav.Link>
                                <Nav.Link as={Link} to="/Aboutus" className={`nav-item-spacing ${location.pathname === '/Aboutus' ? 'active' : ''}`}>About Us</Nav.Link>
                                <Nav.Link as={Link} to="/Destinations" className={`nav-item-spacing ${location.pathname === '/Destinations' ? 'active' : ''}`}>Destinations</Nav.Link>
                                <Nav.Link as={Link} to="/Blogs" className={`nav-item-spacing ${location.pathname === '/Blogs' ? 'active' : ''}`}>Blog</Nav.Link>
                                <Nav.Link as={Link} to="/Contact" className={`nav-item-spacing ${location.pathname === '/Contact' ? 'active' : ''}`}>Contact</Nav.Link>
                            </ul>
                        </Nav>
                    </Navbar.Collapse>

                </Container>
            </Navbar>





        </>
    )
}
