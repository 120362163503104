import React from 'react';

const WhatsAppIcon = () => {
    return (
        <div className="whatsapp-icon">
            <a href="https://wa.me/+919444370254" target="_blank" rel="noopener noreferrer">
            <i class="fa-brands fa-whatsapp fa-beat fa-xl" style={{color: "#white"}}></i>
            </a>
        </div>
    );
};

export default WhatsAppIcon;