import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css'
import 'swiper/css/pagination';
import { Card, Col, Container, Row } from 'react-bootstrap';

export default function Testimonial() {
    const [activeSection, setActiveSection] = useState('expert');


    const handleSectionClick = (section) => {
        console.log('Section clicked:', section);
        setActiveSection(section);

    };

    const getBgColor = (section) => {
        return activeSection === section ? '#14AFE5' : '#126DB1';
    };


    return (
        <>
            <div className='dest_back'>

                <div className='client_test'>
                    <div className='client'>
                        <h1>Tales of Joy</h1>
                        <p>Our trips are always a shore thing, hear it straight from our happy travellers!</p>
                    </div>
                    <section className="testimonial">
                        <Container>
                            <Swiper
                                spaceBetween={50}
                                slidesPerView={1}
                                pagination={{ clickable: true }}
                                modules={[Pagination]}
                                breakpoints={{
                                    500: { slidesPerView: 1, spaceBetween: 20 },
                                    768: { slidesPerView: 2, spaceBetween: 30 },
                                    1024: { slidesPerView: 3, spaceBetween: 40 },
                                }}
                                onSlideChange={() => console.log('slide change')}
                                onSwiper={(swiper) => console.log(swiper)}
                            >
                                <SwiperSlide>
                                    <Card style={{ width: '81%', padding: '22px' }}>
                                        <Card.Body>

                                            <div className='card_img'>
                                                <Card.Img src='/images/girly.jpg' />
                                            </div>

                                            <div className='card-text-para'>
                                                <p>Ncidunt vitae semper quis lectu nonquamlacus suspendisse faucibus int
                                                    aenean pharetra magna habitasse platea dictumst quisque sagittis.</p>
                                                <div className="rating">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div>
                                                <h2>Mary Ann</h2>
                                                <p>Customer</p>
                                            </div>
                                        </Card.Body>

                                    </Card>
                                    {/* <div className="card">
                                <div className="image">
                                    <img src="/images/boy.jpg" alt="Card Image" />
                                </div>
                                <h3>Mary Ann</h3>
                                <p>Customer</p>
                                Ncidunt vitae semper quis lectu nonquamlacus suspendisse faucibus int
                                aenean pharetra magna habitasse platea dictumst quisque sagittis.
                            </div> */}
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Card style={{ width: '81%', padding: '22px' }}>
                                        <Card.Body>

                                            <div className='card_img'>
                                                <Card.Img src='/images/boy.jpg' />
                                            </div>

                                            <div className='card-text-para'>
                                                <p>Ncidunt vitae semper quis lectu nonquamlacus suspendisse faucibus int
                                                    aenean pharetra magna habitasse platea dictumst quisque sagittis.</p>
                                                <div className="rating">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div>
                                                <h2>Mary Ann</h2>
                                                <p>Customer</p>
                                            </div>
                                        </Card.Body>

                                    </Card>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Card style={{ width: '81%', padding: '22px' }}>
                                        <Card.Body>

                                            <div className='card_img'>
                                                <Card.Img src='/images/girly.jpg' />
                                            </div>

                                            <div className='card-text-para'>
                                                <p>Ncidunt vitae semper quis lectu nonquamlacus suspendisse faucibus int
                                                    aenean pharetra magna habitasse platea dictumst quisque sagittis.</p>
                                                <div className="rating">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div>
                                                <h2>Mary Ann</h2>
                                                <p>Customer</p>
                                            </div>
                                        </Card.Body>

                                    </Card>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Card style={{ width: '81%', padding: '22px' }}>
                                        <Card.Body>
                                            <div className='card_img'>
                                                <Card.Img src='/images/boy.jpg' />
                                            </div>
                                            <div className='card-text-para'>
                                                <p>Ncidunt vitae semper quis lectu nonquamlacus suspendisse faucibus int
                                                    aenean pharetra magna habitasse platea dictumst quisque sagittis.</p>
                                                <div className="rating">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div>
                                                <h2>Mary Ann</h2>
                                                <p>Customer</p>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </SwiperSlide>
                            </Swiper>
                        </Container>
                    </section>
                    <div className='people_choice'>
                        <div className='people_header'>
                            <h1>
                                Why People Choose Us?
                            </h1>
                            <p>Experience seamless travel planning and unforgettable journeys.</p>
                        </div>
                        <div className='header_nav'>
                            <div className='navigate_items' >
                                <div className='nav-item_flight'
                                    style={{ backgroundColor: getBgColor('expert') }}
                                    onClick={() => handleSectionClick('expert')}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            id="Layer_1"
                                            data-name="Layer 1"
                                            viewBox="0 0 24 24"
                                            width="30"
                                            height="30"
                                            fill="white">
                                            <path d="m8,13h-1v-2.5c0-1.378,1.122-2.5,2.5-2.5h5c1.379,0,2.5,1.122,2.5,2.5v2.5h-1v-2.5c0-.827-.673-1.5-1.5-1.5h-5c-.827,0-1.5.673-1.5,1.5v2.5Zm.5-9.5c0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5-1.57,3.5-3.5,3.5-3.5-1.57-3.5-3.5Zm1,0c0,1.378,1.122,2.5,2.5,2.5s2.5-1.122,2.5-2.5-1.121-2.5-2.5-2.5-2.5,1.122-2.5,2.5Zm6.5,14.5h-2.683l-.92-2.626h-.794l-.92,2.626h-2.683v.795l1.861,1.5-.7,2.343.618.502,2.221-1.44,2.221,1.44.625-.48-.707-2.365,1.861-1.5v-.795Zm-3.011,1.938l.449,1.502-1.438-.933-1.438.933.449-1.502-1.164-.938h1.544l.608-1.735.608,1.735h1.544l-1.164.938Zm11.011-4.938h-2.683l-.92-2.626h-.794l-.92,2.626h-2.683v.795l1.861,1.5-.7,2.343.618.502,2.221-1.44,2.221,1.44.625-.48-.707-2.365,1.861-1.5v-.795Zm-3.011,1.938l.449,1.502-1.438-.933-1.438.933.449-1.502-1.164-.938h1.544l.608-1.735.608,1.735h1.544l-1.164.938Zm-12.989-1.938h-2.683l-.92-2.626h-.794l-.92,2.626H0v.795l1.861,1.5-.7,2.343.618.502,2.221-1.44,2.221,1.44.625-.48-.707-2.365,1.861-1.5v-.795Zm-3.011,1.938l.449,1.502-1.438-.933-1.438.933.449-1.502-1.164-.938h1.544l.608-1.735.608,1.735h1.544l-1.164.938Z" />
                                        </svg>
                                    </span>&nbsp;&nbsp;Expert Knowledge</div>
                                <div className='nav-item_hotel'
                                    style={{ backgroundColor: getBgColor('service') }}
                                    onClick={() => handleSectionClick('service')}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            id="Layer_1"
                                            data-name="Layer 1"
                                            viewBox="0 0 24 24"
                                            width="30"
                                            height="30"
                                            fill="white">
                                            <path d="m21,12c0-.691-.084-1.39-.25-2.082l2.924-1.656-2.464-4.351-2.926,1.657c-1.063-1.04-2.359-1.795-3.784-2.207V0h-5v3.362c-1.425.412-2.72,1.167-3.784,2.207l-2.926-1.657L.326,8.262l2.924,1.656c-.166.692-.25,1.391-.25,2.082s.084,1.39.25,2.082l-2.924,1.655,2.464,4.352,2.926-1.657c1.064,1.04,2.359,1.796,3.784,2.207v3.362h5v-3.362c1.424-.411,2.72-1.167,3.784-2.207l2.926,1.657,2.464-4.352-2.924-1.655c.166-.692.25-1.39.25-2.082Zm1.311,4.114l-1.478,2.611-2.733-1.547-.269.287c-1.07,1.141-2.436,1.938-3.949,2.303l-.382.092v3.14h-3v-3.14l-.382-.092c-1.513-.366-2.879-1.162-3.949-2.303l-.269-.287-2.733,1.547-1.478-2.611,2.73-1.546-.107-.378c-.207-.727-.312-1.464-.312-2.19s.105-1.463.312-2.191l.107-.378-2.73-1.546,1.478-2.61,2.733,1.547.269-.287c1.069-1.141,2.435-1.937,3.949-2.303l.382-.092V1h3v3.14l.382.092c1.514.366,2.879,1.162,3.949,2.303l.269.287,2.733-1.547,1.478,2.61-2.73,1.546.107.378c.207.728.312,1.465.312,2.191s-.105,1.463-.312,2.19l-.107.378,2.73,1.546Zm-5.311-.805c-.283.426-.621.81-1,1.15v-1.46c0-.552-.448-1-1-1h-6c-.552,0-1,.448-1,1v1.46c-.379-.34-.717-.724-1-1.15,0,0,.01-.327.003-.337.015-1.09.904-1.972,1.997-1.972h6c1.094,0,1.982.882,1.997,1.972-.007.01.003.337.003.337Zm-5-3.31c1.654,0,3-1.346,3-3s-1.346-3-3-3-3,1.346-3,3,1.346,3,3,3Zm0-5c1.103,0,2,.897,2,2s-.897,2-2,2-2-.897-2-2,.897-2,2-2Z" />
                                        </svg>
                                    </span>&nbsp;&nbsp;Personalized Service</div>
                                <div className='nav-item_travel'
                                    style={{ backgroundColor: getBgColor('support') }}
                                    onClick={() => handleSectionClick('support')}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            id="Layer_1"
                                            data-name="Layer 1"
                                            viewBox="0 0 24 24"
                                            width="30"
                                            height="30"
                                            fill="white">
                                            <path d="m8.37,18.845l.224,1.045C3.745,19.202,0,15.035,0,10,0,4.486,4.486,0,10,0c5.407,0,9.815,4.317,9.984,9.684-.299-.493-.679-.896-1.118-1.172-.712-4.255-4.411-7.512-8.866-7.512C5.037,1,1,5.038,1,10c0,4.406,3.184,8.075,7.37,8.845Zm15.63-1.845v5c0,1.103-.897,2-2,2h-10.479l-1.521-7.095.009-1.905h6.185l-.58-3.265c-.022-.425-.031-.58.021-.689.133-.401.401-.716.764-.894.392-.192.846-.199,1.28-.02.253.104.502.366.683.718l2.192,4.149h1.447c1.103,0,2,.897,2,2Zm-11.673,6h7.682v-7h-.059l-2.478-4.688c-.104-.202-.196-.265-.197-.266-.145-.058-.307-.061-.438.003-.11.054-.196.154-.246.285l.013.289.782,4.376h-6.382l-.005.855,1.327,6.145Zm10.673-6c0-.552-.448-1-1-1h-.991v7h.991c.552,0,1-.448,1-1v-5ZM14.342,6.394l-5.061,4.985c-.154.156-.419.161-.583-.004l-2.849-2.79-.699.715,2.844,2.785c.267.267.621.415.998.415s.731-.147.994-.412l5.057-4.982-.701-.712Z" />
                                        </svg>
                                    </span>&nbsp;&nbsp;Support</div>
                                <div className='nav-item_trip'
                                    style={{ backgroundColor: getBgColor('price') }}
                                    onClick={() => handleSectionClick('price')}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            id="Layer_1"
                                            data-name="Layer 1"
                                            viewBox="0 0 24 24"
                                            width="30"
                                            height="30"
                                            fill="white">
                                            <path d="M24,5.5V15.6c0,2-.78,3.89-2.2,5.3l-2.95,2.95c-.1,.1-.23,.15-.35,.15s-.26-.05-.35-.15c-.2-.2-.2-.51,0-.71l2.95-2.95c1.23-1.23,1.9-2.86,1.9-4.6V5.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5V13.5s0,.04,0,.06c-.01,.55-.21,1.09-.58,1.54l-2.64,2.75c-.1,.1-.23,.15-.36,.15s-.25-.05-.35-.14c-.2-.19-.21-.51-.01-.71l2.62-2.72c.22-.26,.33-.58,.33-.91,0,0,0-.01,0-.02v-.05c-.01-.34-.14-.68-.38-.96-.27-.3-.63-.47-1.03-.5-.39,0-.78,.11-1.08,.38l-2.73,2.51c-.52,.47-.93,1.03-1.23,1.66,.3,.75,.46,1.56,.46,2.38v4.56c0,.28-.22,.5-.5,.5s-.5-.22-.5-.5v-4.56c0-1.53-.65-3.01-1.78-4.05l-2.73-2.52c-.29-.26-.68-.38-1.08-.38-.4,.02-.77,.2-1.03,.5-.49,.55-.51,1.39-.03,1.96l2.59,2.7c.19,.2,.19,.52-.01,.71-.2,.19-.52,.19-.71-.01l-2.62-2.72c-.38-.45-.57-.99-.6-1.53,0-.03,0-.06,0-.1V5.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5V15.6c0,1.74,.68,3.37,1.9,4.6l2.95,2.95c.2,.2,.2,.51,0,.71-.1,.1-.23,.15-.35,.15s-.26-.05-.35-.15l-2.95-2.95c-1.42-1.42-2.2-3.3-2.2-5.3V5.5c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5v6c.39-.29,.86-.47,1.36-.49,.67-.05,1.31,.19,1.8,.63l2.74,2.52c.43,.39,.79,.84,1.1,1.32,.3-.48,.67-.93,1.1-1.32l2.73-2.52c.5-.45,1.14-.68,1.81-.64,.5,.03,.97,.2,1.36,.49V5.5c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5Zm-13.97-.13l3.62,1.21c.51,.17,.85,.64,.85,1.18,0,.69-.56,1.24-1.24,1.24h-2.31c-.62,0-1.2-.33-1.52-.88-.14-.24-.44-.32-.68-.18-.24,.14-.32,.44-.18,.68,.49,.85,1.4,1.38,2.38,1.38h.55v.5c0,.28,.22,.5,.5,.5s.5-.22,.5-.5v-.5h.76c1.24,0,2.24-1.01,2.24-2.24,0-.97-.62-1.82-1.53-2.13l-3.62-1.21c-.51-.17-.85-.64-.85-1.18,0-.69,.56-1.24,1.24-1.24h2.31c.62,0,1.2,.33,1.52,.88,.14,.24,.44,.32,.68,.18,.24-.14,.32-.44,.18-.68-.49-.85-1.4-1.38-2.38-1.38h-.55V.5c0-.28-.22-.5-.5-.5s-.5,.22-.5,.5v.5h-.76c-1.24,0-2.24,1.01-2.24,2.24,0,.97,.62,1.82,1.53,2.13Z" /></svg>
                                    </span>&nbsp;&nbsp;Affordable Prices</div>
                                <div className='nav-item_arrow'
                                    style={{ backgroundColor: getBgColor('plan') }}
                                    onClick={() => handleSectionClick('plan')}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            id="Layer_1"
                                            data-name="Layer 1"
                                            viewBox="0 0 24 24"
                                            width="30"
                                            height="30"
                                            fill="white">
                                            <path d="M11.026,12H4v-1H12.028c-.362,.305-.696,.64-1.003,1Zm-7.026,4h5.14c.061-.34,.135-.675,.235-1H4v1Zm20,1.5c0,3.584-2.916,6.5-6.5,6.5s-6.5-2.916-6.5-6.5,2.916-6.5,6.5-6.5,6.5,2.916,6.5,6.5Zm-1,0c0-3.033-2.468-5.5-5.5-5.5s-5.5,2.467-5.5,5.5,2.468,5.5,5.5,5.5,5.5-2.467,5.5-5.5Zm-6.02,1.452c-.082,.082-.144,.091-.241-.006l-1.856-1.799-.695,.718,1.851,1.793c.22,.221,.513,.342,.824,.342s.605-.122,.822-.339l3.456-3.399-.701-.713-3.459,3.402Zm-1.98-11.952H4v1H15v-1ZM2.5,23c-.827,0-1.5-.673-1.5-1.5V3H18v6.032c.339,.022,.673,.062,1,.122V2h-3V0h-1V2h-3V0h-1V2h-3V0h-1V2h-3V0h-1V2H0V21.5c0,1.378,1.121,2.5,2.5,2.5H12.028c-.36-.303-.689-.641-.992-1H2.5Z" />
                                        </svg>
                                    </span>&nbsp;&nbsp;Hassle-Free Planning</div>
                                {/* <ul className='list_nav_items'>
                                <li className='flight'>Flight Ticket</li>
                                <li>Flight Ticket</li>
                                <li>Flight Ticket</li>
                                <li>Flight Ticket</li>
                                <li><i class="fa-solid fa-arrow-right fa-2xl" style={{color: "#ffffff"}}></i></li>
                            </ul> */}
                            </div>

                        </div>
                        <div className='pic_row-div'>
                            {activeSection === 'expert' && (
                                <Container>
                                    <Row>
                                        <Col md={6} style={{ padding: '0' }}>

                                            <div className='garden-img'>
                                                {/* <img src='/images/know.jpg'  /> */}
                                            </div>
                                        </Col>
                                        <Col md={6} style={{ padding: '0' }}>
                                            <div className='garden-col'>
                                                <div className='text_garden' style={{ padding: '10%' }}>
                                                    <h4 style={{fontWeight:'bold',textAlign:'justify'}}>Expert Knowledge</h4>
                                                    <p>We specialize in crafting personalized itineraries that cater to various preferences, ensuring unforgettable experiences in top destinations. Our commitment to excellence means we provide not only well-researched and carefully curated travel packages but also exceptional customer service. Whether you're seeking adventure, relaxation, or cultural immersion, Holiday Star's expertise and passion for travel 
                                                        make us the perfect choice for your next journey.</p>

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            )}
                        </div>
                        <div className='pic_row-div'>
                            {activeSection === 'service' && (
                                <Container>
                                    <Row>
                                        <Col md={6} style={{ padding: '0' }}>

                                            <div className='service-img'>
                                                {/* <img src='/images/know.jpg'  /> */}
                                            </div>
                                        </Col>
                                        <Col md={6} style={{ padding: '0' }}>
                                            <div className='garden-col'>
                                                <div className='text_garden' style={{ padding: '10%' }}>
                                                    <h4 style={{fontWeight:'bold',textAlign:'justify'}}>Personalized Service</h4>
                                                    <p>We believe that every traveler is unique, and so should be their travel experience. Our personalized services are designed to cater to your individual preferences and interests, ensuring that your journey is tailored to your desires. From custom itinerary planning to selecting the perfect accommodations and activities, we work closely with you to create a seamless and memorable experience. Whether you're looking for a romantic getaway, a family adventure, or a solo exploration, our dedicated team takes the time to understand your needs and deliver a travel experience that reflects your personal tastes and aspirations.</p>

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            )}
                        </div>
                        <div className='pic_row-div'>
                            {activeSection === 'support' && (
                                <Container>
                                    <Row>
                                        <Col md={6} style={{ padding: '0' }}>

                                            <div className='support-img'>
                                                {/* <img src='/images/know.jpg'  /> */}
                                            </div>
                                        </Col>
                                        <Col md={6} style={{ padding: '0' }}>
                                            <div className='garden-col'>
                                                <div className='text_garden' style={{ padding: '10%' }}>
                                                    <h4 style={{fontWeight:'bold',textAlign:'justify'}}>Support</h4>
                                                    <p>We understand that a seamless travel experience requires 
                                                        continuous support. Whether you're facing an unexpected change in plans, need help with a reservation, or have questions during your trip, our dedicated support team is always available to assist you. From the
                                                         moment you start planning your trip until you return home, our dedicated team is here to assist you every step of the way. We offer comprehensive support services to ensure that all your questions and concerns are promptly addressed. Our priority is to make your travel experience smooth, stress-free, and enjoyable, giving you peace of mind to focus on creating lasting memories</p>

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            )}
                        </div>
                        <div className='pic_row-div'>
                            {activeSection === 'price' && (
                                <Container>
                                    <Row>
                                        <Col md={6} style={{ padding: '0' }}>

                                            <div className='price-img'>
                                                {/* <img src='/images/know.jpg'  /> */}
                                            </div>
                                        </Col>
                                        <Col md={6} style={{ padding: '0' }}>
                                            <div className='garden-col'>
                                                <div className='text_garden' style={{ padding: '10%' }}>
                                                    <h4 style={{fontWeight:'bold',textAlign:'justify'}}>Affordable Prices</h4>
                                                    <p>We work diligently to negotiate the best deals with hotels and local partners, ensuring that you receive outstanding value for your money. Our transparent pricing structure means there are no hidden costs, allowing you to plan your budget with confidence. Whether you're looking for a luxury escape or a budget-friendly adventure, we provide a range of options to suit your financial preferences. You can enjoy memorable journeys and unique experiences at prices that won't break the bank.
                                                        </p>

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            )}
                        </div>
                        <div className='pic_row-div'>
                            {activeSection === 'plan' && (
                                <Container>
                                    <Row>
                                        <Col md={6} style={{ padding: '0' }}>

                                            <div className='plan-img'>
                                                {/* <img src='/images/know.jpg'  /> */}
                                            </div>
                                        </Col>
                                        <Col md={6} style={{ padding: '0' }}>
                                            <div className='garden-col'>
                                                <div className='text_garden' style={{ padding: '10%' }}>
                                                    <h4 style={{fontWeight:'bold',textAlign:'justify'}}>Hassle-Free Planning</h4>
                                                    <p>We handle all the details, from booking flights and accommodations to arranging transportation and activities, so you can focus on enjoying your trip. Our experienced team carefully curates every aspect of your journey, ensuring a smooth and seamless experience from start to finish. 
                                                        With our expertise and attention to detail, you can relax and trust that everything has been taken care of. Whether you're planning a quick getaway or an extended vacation, we make travel planning effortless and enjoyable,
                                                         giving you more time to anticipate and savor your adventure.</p>

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
