import React, { useEffect } from "react";
import AOS from "aos";
import { Col, Container, Row, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Blog() {
  const blogPosts = [
    {
      id: 1,
      image: "/images/mala.jpg",
      
      // categories: "Sea,Travel",
      title: "Malaysian Tour Guide: Best Times for a Perfect Vacation",
      link:"/Malasiyanblog",
      // description:
      //   "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id,…",
    },
    {
      id: 2,
      image: "/images/andamon.jpg",
     
      // categories: "Sea,Travel",
      title: "Andaman Islands Uncovered: When to Visit the Ultimate Tropical Getaway!",
      link:"/Andamanblog",
      // description:
      //   "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id,…",
    },
    {
      id: 3,
      image: "/images/simgam.jpg",
 
      title: "Singapore In Style: Your Guide to The Best Times to Travel and Things ToDo",
      link:"/Singaporeblog",
      // description:
      //   "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id,…",
    },
  ];
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);
  return (
    <div className="blog-bg-card">
      <div className="blog-card">
        <div className="blog-header" data-aos="fade-down" data-aos-duration="3000">
          <h1>Our Blog</h1>
          <p>
            Turn wanderlust into a star-studded adventure—discover the insights that make holidays shine on our blog!
          </p>
        </div>
        <div className="card-blog" data-aos="fade-up" data-aos-duration="2000">
          <Container>
            <Row>
              {blogPosts.map((post) => (
                <Col key={post.id} md={4}>
                  <Link to={post.link} className="blog-link">
                    <Card className="bloger">
                      <Card.Body>
                        <div className="image-date">
                          <Card.Img src={post.image} />
                          {/* <div className="date">
                            <h2>{}</h2>
                            <h6>{}</h6>
                          </div> */}
                        </div>
                        <div className="text_card">
                          <Card.Text>
                            <div className="locate">
                              <p>{post.categories}</p>
                            </div>
                          </Card.Text>
                          <Card.Title>{post.title}</Card.Title>
                          <p className="locate">{post.description}</p>
                        </div>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}
