import React, { useState } from "react";
import NavbarComponent from "./Navbar";
import { Link } from "react-router-dom";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import Footer from "./Footer";


export default function Contact() {

    const [formData,setFormData]=useState({
        name: "",
        email: "",
        phone: "",
        type: "",
        message: ""
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    return (
        <>
      
            <div className="about-bg-subhead">
                <div className="about-subhead">
                    <div className="about-navigate">
                        <div className="about-heading">
                            <h1>Contact Us</h1>
                        </div>
                        <div className="about-home-navigate">
                            <ul>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <i className="fa-solid fa-angle-right" style={{ color: '#ffffff' }}></i>
                                </li>
                                <li>
                                    <p>Contact Us</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-form">
                <div className="contact-form-heading">
                    <h1>Send Us A Message</h1>
                </div>
                <Container>
                    <Form>
                        <Row>
                            <Col md="6">
                                <Form.Group controlId="formName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" name="name"  value={formData.name} onChange={handleInputChange} placeholder="Name" required />
                                </Form.Group>
                            </Col>
                            <Col md="6">
                                <Form.Group controlId="formEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" name="email"  value={formData.email} onChange={handleInputChange} placeholder="Email" required />
                                </Form.Group>
                            </Col>
                            
                        </Row>
                        <Row>
                        <Col md="6">
                                <Form.Group controlId="formPhone">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control type="text" name="phone"  value={formData.phone} onChange={handleInputChange} placeholder="Phone" required />
                                </Form.Group>
                            </Col>
                            <Col md="6">
                                <Form.Group controlId="formType">
                                    <Form.Label>Type</Form.Label>
                                    <Form.Control as="select" name="type"  value={formData.type} onChange={handleInputChange} required>
                                        <option>Select</option>
                                        <option>Enquiry</option>
                                        <option>Feedback</option>
                                        <option>Suggestion</option>
                                        <option>Complaint</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="formMessage">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control as="textarea" rows={3} name="message"  value={formData.message} onChange={handleInputChange} placeholder="Message" required />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <Button variant="danger" type="submit" className="mt-3">
                                    Send
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
            <div className="contact-poster">
                <img src="/images/bg11.webp" style={{width:'100%',paddingTop:'2%'}}/>
            </div>
        <Footer/>
        </>
    );
}
