import React from "react";
import NavbarComponent from "./Navbar";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css'
import 'swiper/css/pagination';
import Footer from "./Footer";

export default function Aboutus() {
    return (
        <>

            <div className="about-bg-subhead">
                <div className="about-subhead">
                    <div className="about-navigate">
                        <div className="about-heading">
                            <h1>About Us</h1>
                        </div>
                        <div className="about-home-navigate">
                            <ul>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <i class="fa-solid fa-angle-right" style={{ color: '#ffffff' }}></i>
                                </li>
                                <li>
                                    <p >About Us</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about-details">
                <Container>
                    <div className="about-detail-heading" data-aos="fade-down"

                        data-aos-duration="3000" >
                        <h1>Explore, Dream, Discover with Holiday Star</h1>
                        <h5>Discover the world with Holiday Star! We are your trusted partner in creating unforgettable travel experiences. Whether you're seeking adventure, relaxation, or cultural exploration, Holiday Star has the perfect tour package for you. Let us take you on a journey to the most stunning destinations across the globe.
                            <br /><br />


                            We are dedicated to curating exceptional travel experiences that exceed expectations.

                            We specialize in crafting personalized journeys to Malaysia, Singapore, Thailand, and beyond.

                            Our passion for travel drives us to ensure every detail of your trip is meticulously planned, allowing you to immerse yourself in unforgettable adventures with peace of mind.

                        </h5>
                    </div>
                </Container>
            </div>
            <div className="about-partner">
                <div className="partner-bg">
                    <div className="partner-invest" data-aos="fade-down" data-aos-duration="2000">
                        <h1>Why People Choose Us?</h1>
                        <h6>Experience seamless travel planning and unforgettable journeys.</h6>
                    </div>
                    <Swiper
                        data-aos="fade-down" data-aos-duration="2000"
                        spaceBetween={50}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        modules={[Pagination]}
                        breakpoints={{
                            500: { slidesPerView: 1, spaceBetween: 20 },
                            768: { slidesPerView: 2, spaceBetween: 30 },
                            1024: { slidesPerView: 3, spaceBetween: 40 },
                        }}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        <SwiperSlide style={{width:'200px'}}>
                            <div className="swiper-slide-content" data-aos="fade-down" data-aos-duration="2000">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    id="Layer_1"
                                    data-name="Layer 1"
                                    viewBox="0 0 24 24"
                                    width="100"
                                    height="100"
                                    fill="white">
                                    <path d="m8,13h-1v-2.5c0-1.378,1.122-2.5,2.5-2.5h5c1.379,0,2.5,1.122,2.5,2.5v2.5h-1v-2.5c0-.827-.673-1.5-1.5-1.5h-5c-.827,0-1.5.673-1.5,1.5v2.5Zm.5-9.5c0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5-1.57,3.5-3.5,3.5-3.5-1.57-3.5-3.5Zm1,0c0,1.378,1.122,2.5,2.5,2.5s2.5-1.122,2.5-2.5-1.121-2.5-2.5-2.5-2.5,1.122-2.5,2.5Zm6.5,14.5h-2.683l-.92-2.626h-.794l-.92,2.626h-2.683v.795l1.861,1.5-.7,2.343.618.502,2.221-1.44,2.221,1.44.625-.48-.707-2.365,1.861-1.5v-.795Zm-3.011,1.938l.449,1.502-1.438-.933-1.438.933.449-1.502-1.164-.938h1.544l.608-1.735.608,1.735h1.544l-1.164.938Zm11.011-4.938h-2.683l-.92-2.626h-.794l-.92,2.626h-2.683v.795l1.861,1.5-.7,2.343.618.502,2.221-1.44,2.221,1.44.625-.48-.707-2.365,1.861-1.5v-.795Zm-3.011,1.938l.449,1.502-1.438-.933-1.438.933.449-1.502-1.164-.938h1.544l.608-1.735.608,1.735h1.544l-1.164.938Zm-12.989-1.938h-2.683l-.92-2.626h-.794l-.92,2.626H0v.795l1.861,1.5-.7,2.343.618.502,2.221-1.44,2.221,1.44.625-.48-.707-2.365,1.861-1.5v-.795Zm-3.011,1.938l.449,1.502-1.438-.933-1.438.933.449-1.502-1.164-.938h1.544l.608-1.735.608,1.735h1.544l-1.164.938Z" />
                                </svg>
                            </div>
                            <div className="text-icon-container">Expert Knowledge</div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide-content" data-aos="fade-down" data-aos-duration="2000">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                            id="Layer_1"
                                            data-name="Layer 1"
                                            viewBox="0 0 24 24"
                                            width="100"
                                            height="100"
                                            fill="white">
                                            <path d="m21,12c0-.691-.084-1.39-.25-2.082l2.924-1.656-2.464-4.351-2.926,1.657c-1.063-1.04-2.359-1.795-3.784-2.207V0h-5v3.362c-1.425.412-2.72,1.167-3.784,2.207l-2.926-1.657L.326,8.262l2.924,1.656c-.166.692-.25,1.391-.25,2.082s.084,1.39.25,2.082l-2.924,1.655,2.464,4.352,2.926-1.657c1.064,1.04,2.359,1.796,3.784,2.207v3.362h5v-3.362c1.424-.411,2.72-1.167,3.784-2.207l2.926,1.657,2.464-4.352-2.924-1.655c.166-.692.25-1.39.25-2.082Zm1.311,4.114l-1.478,2.611-2.733-1.547-.269.287c-1.07,1.141-2.436,1.938-3.949,2.303l-.382.092v3.14h-3v-3.14l-.382-.092c-1.513-.366-2.879-1.162-3.949-2.303l-.269-.287-2.733,1.547-1.478-2.611,2.73-1.546-.107-.378c-.207-.727-.312-1.464-.312-2.19s.105-1.463.312-2.191l.107-.378-2.73-1.546,1.478-2.61,2.733,1.547.269-.287c1.069-1.141,2.435-1.937,3.949-2.303l.382-.092V1h3v3.14l.382.092c1.514.366,2.879,1.162,3.949,2.303l.269.287,2.733-1.547,1.478,2.61-2.73,1.546.107.378c.207.728.312,1.465.312,2.191s-.105,1.463-.312,2.19l-.107.378,2.73,1.546Zm-5.311-.805c-.283.426-.621.81-1,1.15v-1.46c0-.552-.448-1-1-1h-6c-.552,0-1,.448-1,1v1.46c-.379-.34-.717-.724-1-1.15,0,0,.01-.327.003-.337.015-1.09.904-1.972,1.997-1.972h6c1.094,0,1.982.882,1.997,1.972-.007.01.003.337.003.337Zm-5-3.31c1.654,0,3-1.346,3-3s-1.346-3-3-3-3,1.346-3,3,1.346,3,3,3Zm0-5c1.103,0,2,.897,2,2s-.897,2-2,2-2-.897-2-2,.897-2,2-2Z" />
                                        </svg>
                            </div>
                            <div className="text-icon-container">Personalized Service</div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide-content" data-aos="fade-down" data-aos-duration="2000">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                            id="Layer_1"
                                            data-name="Layer 1"
                                            viewBox="0 0 24 24"
                                            width="100"
                                            height="100"
                                            fill="white">
                                            <path d="m8.37,18.845l.224,1.045C3.745,19.202,0,15.035,0,10,0,4.486,4.486,0,10,0c5.407,0,9.815,4.317,9.984,9.684-.299-.493-.679-.896-1.118-1.172-.712-4.255-4.411-7.512-8.866-7.512C5.037,1,1,5.038,1,10c0,4.406,3.184,8.075,7.37,8.845Zm15.63-1.845v5c0,1.103-.897,2-2,2h-10.479l-1.521-7.095.009-1.905h6.185l-.58-3.265c-.022-.425-.031-.58.021-.689.133-.401.401-.716.764-.894.392-.192.846-.199,1.28-.02.253.104.502.366.683.718l2.192,4.149h1.447c1.103,0,2,.897,2,2Zm-11.673,6h7.682v-7h-.059l-2.478-4.688c-.104-.202-.196-.265-.197-.266-.145-.058-.307-.061-.438.003-.11.054-.196.154-.246.285l.013.289.782,4.376h-6.382l-.005.855,1.327,6.145Zm10.673-6c0-.552-.448-1-1-1h-.991v7h.991c.552,0,1-.448,1-1v-5ZM14.342,6.394l-5.061,4.985c-.154.156-.419.161-.583-.004l-2.849-2.79-.699.715,2.844,2.785c.267.267.621.415.998.415s.731-.147.994-.412l5.057-4.982-.701-.712Z" />
                                        </svg>
                            </div>
                            <div className="text-icon-container">24/7 Support</div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide-content" data-aos="fade-down" data-aos-duration="2000">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                            id="Layer_1"
                                            data-name="Layer 1"
                                            viewBox="0 0 24 24"
                                            width="100"
                                            height="100"
                                            fill="white">
                                            <path d="M24,5.5V15.6c0,2-.78,3.89-2.2,5.3l-2.95,2.95c-.1,.1-.23,.15-.35,.15s-.26-.05-.35-.15c-.2-.2-.2-.51,0-.71l2.95-2.95c1.23-1.23,1.9-2.86,1.9-4.6V5.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5V13.5s0,.04,0,.06c-.01,.55-.21,1.09-.58,1.54l-2.64,2.75c-.1,.1-.23,.15-.36,.15s-.25-.05-.35-.14c-.2-.19-.21-.51-.01-.71l2.62-2.72c.22-.26,.33-.58,.33-.91,0,0,0-.01,0-.02v-.05c-.01-.34-.14-.68-.38-.96-.27-.3-.63-.47-1.03-.5-.39,0-.78,.11-1.08,.38l-2.73,2.51c-.52,.47-.93,1.03-1.23,1.66,.3,.75,.46,1.56,.46,2.38v4.56c0,.28-.22,.5-.5,.5s-.5-.22-.5-.5v-4.56c0-1.53-.65-3.01-1.78-4.05l-2.73-2.52c-.29-.26-.68-.38-1.08-.38-.4,.02-.77,.2-1.03,.5-.49,.55-.51,1.39-.03,1.96l2.59,2.7c.19,.2,.19,.52-.01,.71-.2,.19-.52,.19-.71-.01l-2.62-2.72c-.38-.45-.57-.99-.6-1.53,0-.03,0-.06,0-.1V5.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5V15.6c0,1.74,.68,3.37,1.9,4.6l2.95,2.95c.2,.2,.2,.51,0,.71-.1,.1-.23,.15-.35,.15s-.26-.05-.35-.15l-2.95-2.95c-1.42-1.42-2.2-3.3-2.2-5.3V5.5c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5v6c.39-.29,.86-.47,1.36-.49,.67-.05,1.31,.19,1.8,.63l2.74,2.52c.43,.39,.79,.84,1.1,1.32,.3-.48,.67-.93,1.1-1.32l2.73-2.52c.5-.45,1.14-.68,1.81-.64,.5,.03,.97,.2,1.36,.49V5.5c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5Zm-13.97-.13l3.62,1.21c.51,.17,.85,.64,.85,1.18,0,.69-.56,1.24-1.24,1.24h-2.31c-.62,0-1.2-.33-1.52-.88-.14-.24-.44-.32-.68-.18-.24,.14-.32,.44-.18,.68,.49,.85,1.4,1.38,2.38,1.38h.55v.5c0,.28,.22,.5,.5,.5s.5-.22,.5-.5v-.5h.76c1.24,0,2.24-1.01,2.24-2.24,0-.97-.62-1.82-1.53-2.13l-3.62-1.21c-.51-.17-.85-.64-.85-1.18,0-.69,.56-1.24,1.24-1.24h2.31c.62,0,1.2,.33,1.52,.88,.14,.24,.44,.32,.68,.18,.24-.14,.32-.44,.18-.68-.49-.85-1.4-1.38-2.38-1.38h-.55V.5c0-.28-.22-.5-.5-.5s-.5,.22-.5,.5v.5h-.76c-1.24,0-2.24,1.01-2.24,2.24,0,.97,.62,1.82,1.53,2.13Z" />
                                            </svg>
                            </div>
                            <div className="text-icon-container">Affordable Prices</div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide-content" data-aos="fade-down" data-aos-duration="2000">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                            id="Layer_1"
                                            data-name="Layer 1"
                                            viewBox="0 0 24 24"
                                            width="100"
                                            height="100"
                                            fill="white">
                                            <path d="M11.026,12H4v-1H12.028c-.362,.305-.696,.64-1.003,1Zm-7.026,4h5.14c.061-.34,.135-.675,.235-1H4v1Zm20,1.5c0,3.584-2.916,6.5-6.5,6.5s-6.5-2.916-6.5-6.5,2.916-6.5,6.5-6.5,6.5,2.916,6.5,6.5Zm-1,0c0-3.033-2.468-5.5-5.5-5.5s-5.5,2.467-5.5,5.5,2.468,5.5,5.5,5.5,5.5-2.467,5.5-5.5Zm-6.02,1.452c-.082,.082-.144,.091-.241-.006l-1.856-1.799-.695,.718,1.851,1.793c.22,.221,.513,.342,.824,.342s.605-.122,.822-.339l3.456-3.399-.701-.713-3.459,3.402Zm-1.98-11.952H4v1H15v-1ZM2.5,23c-.827,0-1.5-.673-1.5-1.5V3H18v6.032c.339,.022,.673,.062,1,.122V2h-3V0h-1V2h-3V0h-1V2h-3V0h-1V2h-3V0h-1V2H0V21.5c0,1.378,1.121,2.5,2.5,2.5H12.028c-.36-.303-.689-.641-.992-1H2.5Z" />
                                        </svg>
                            </div>
                            <div className="text-icon-container">Hassle-Free Planning</div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            <Footer />

        </>

    )
}