import React from "react";
import NavbarComponent from "./Navbar";
import Plansearch from "./Plansearch";
import Resort from "./Resort";
import Testimonial from "./Testimonial";
import Blog from "./Blog";
import Footer from "./Footer";
import Destination from "./Destination";

export default function Home(){
    return(
        <>
        <div className="home-pg">
            {/* <NavbarComponent/> */}
            <Plansearch/>
            <Destination/>
             <Resort/>
             <Testimonial/>
            <Blog/> 
           <Footer/>
        </div>
        
        </>
    )
}