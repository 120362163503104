import React from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import AnimatedTextCharacter from "./AnimatedTextCharacter";
import AnimatedTextWord from "./AnimatedTextWord";

export default function Plansearch() {
    return (
        <>
            <div id="plansearch">
                <div className="search">
                    <div className="travel_ship">
                        <div className="destination">
                            <div className="travel_plan">
                                {/* <div className="travel_text">
                                    <h5>
                                        <AnimatedTextCharacter text="
                                        CONTACT US TO GET EXCITING TRAVEL PLANS"/></h5>
                                </div> */}
                                <div className="luxury_text">
                                    <h1>
                                        <AnimatedTextCharacter text="Welcome to Holiday Star!" />
                                        <AnimatedTextCharacter text="Your gateway to unforgettable journeys." /></h1>
                                </div>
                            </div>
                            {/* <div className="search_bar">
                            <Container className="search_backcolor">
                                <Row className="align-items-center">
                                    <Col md={3}>
                                        <Form.Group controlId="formLocation">
                                            <Form.Control as="select" className="custom-select">
                                                <option>Location</option>
                                                <option value="1">Location 1</option>
                                                <option value="2">Location 2</option>
                                                <option value="3">Location 3</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group controlId="formTripType">
                                            <Form.Control as="select" className="custom-select">
                                                <option>Trip type</option>
                                                <option value="1">Trip type 1</option>
                                                <option value="2">Trip type 2</option>
                                                <option value="3">Trip type 3</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group controlId="formHowLong">
                                            <Form.Control as="select" className="custom-select">
                                                <option>How long</option>
                                                <option value="1">1 day</option>
                                                <option value="2">2 days</option>
                                                <option value="3">3 days</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Button type="submit" className="search-button">
                                            Search
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </div> */}
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}